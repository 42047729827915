<template>
  <div class="create-contract-container">
    <schedule-operation-bar
      ref="operationBar"
      class="operation-bar"
      :job-data.sync="jobDataInner"
      :canEdit="isJobCreatorOrCustomerEditView"
      :hasSysAdminPermission="hasSysAdminPermission"
      @select-order="contractVisible=true"
      @submit="submitJobData"
      @submitReferenceNo="submitReferenceNo"
      v-on="$listeners">
    </schedule-operation-bar>
    <div class="form" style="overflow-y: auto; height: calc(100% - 42px);">
      <!-- Delivered && loading -->
      <base-detail-card
        title="Files"
        v-if="jobData.status && [3, 4].includes(jobData.status)"
      >
        <job-files :jobFiles.sync="jobFiles" />
      </base-detail-card>
       <!--Delivered Information, deliveredInfoFormData-->
      <base-detail-card
       title="Delivered Information"
       v-loading="!jobDataInner"
      >
      <el-form
        v-if="jobDataInner.type === 1"
        ref="deliveredScheduleForm"
        :model="deliveredInfoFormData"
        label-position="top"
        :validate-on-rule-change="false">
        <el-row :gutter="10">
          <el-col :sm="12" :md="8" :lg="4">
            <el-form-item
              label="Product Name">
              <el-input
                class="single-border"
                readonly
                :value="(jobDataInner||{}).productName">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12" :md="8" :lg="4">
            <el-form-item
              label="Barge Name">
              <el-input
                class="single-border"
                readonly
                :value="(jobDataInner||{}).bargeName">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12" :md="8" :lg="4">
            <el-form-item
              label="eBDN Date" style="width: 100%">
              <el-date-picker
                class="single-border"
                v-model="(jobDataInner||{}).docDate"
                type="date"
                readonly>
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="12" :md="8" :lg="4">
            <el-form-item
              label="Delivered Quantity">
              <el-input
                class="single-border"
                readonly
                :value="(jobDataInner||{}).docQty">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12" :md="8" :lg="4">
            <el-form-item
              label="ETC">
              <el-date-picker
                class="single-border"
                v-model="(jobDataInner||{}).etc"
                type="date"
                readonly>
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :sm="12" :md="8" :lg="4">
            <el-form-item
              v-if="(jobDataInner||{}).bdnPdfId"
              label="BDN Document"
            >
              <el-button
                type="primary" plain
                @click="previewFileMixin((jobDataInner||{}).bdnPdfId)"
                style="width: 100%"
              >
                {{ (jobDataInner||{}).docNumber }}
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <time-sheet-information v-if="jobDataInner?.timesheet" :jobDataInner ="jobDataInner" :jobData ="jobData"/>
      </el-form>
     </base-detail-card>

      <!--Loading Information, loadingInfoFormData need to test more -->
      <base-detail-card
       title="Loading Information"
         v-if="jobDataInner.type === 2"
       v-loading="!jobDataInner"
      >
      <el-form

        ref="loadingInfoForm"
        :model="loadingInfoFormData"
        label-position="top"
        :validate-on-rule-change="false">
        <el-row :gutter="10">
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item
                label="Product Name">
                <el-input
                  class="single-border"
                  readonly
                  :value="(jobDataInner||{}).productName">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item
                label="Barge Name">
                <el-input
                  class="single-border"
                  readonly
                  :value="(jobDataInner||{}).bargeName">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item
                label="eBDN Date" style="width: 100%">
                <el-date-picker
                  class="single-border"
                  v-model="(jobDataInner||{}).docDate"
                  type="date"
                  readonly>
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item
                label="Delivered Quantity">
                <el-input
                  class="single-border"
                  readonly
                  :value="(jobDataInner||{}).docQty">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item
                label="ETC">
                <el-date-picker
                  class="single-border"
                  v-model="(jobDataInner||{}).etc"
                  type="date"
                  readonly>
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col  :sm="12" :md="8" :lg="4">
              <el-form-item
                v-if="(jobDataInner||{}).cqPdfId"
                label="Documents"
              >
                <el-button
                  type="primary" plain
                  @click="previewFileMixin((jobDataInner||{}).cqPdfId)"
                  style="width: 100%"
                >
                  [{{ (jobDataInner||{}).docNumber }}] CQ File
                </el-button>
              </el-form-item>
            </el-col>
            <el-col :sm="12" :md="8" :lg="4">
              <el-form-item
                v-if="(jobDataInner||{}).cqPdfId"
                style="margin-top: 18px;"
              >
                <el-button
                  type="primary" plain
                  @click="previewFileMixin((jobDataInner||{}).coqPdfId)"
                  style="width: 100%"
                >
                  [{{ (jobDataInner||{}).docNumber }}] COQ File
                </el-button>
              </el-form-item>
            </el-col>
        </el-row>
        <time-sheet-information v-if="jobDataInner?.timesheet" :jobDataInner ="jobDataInner" :jobData ="jobData"/>
      </el-form>
    </base-detail-card>
      <!-- Do not remove, might need in future -->
      <!-- <el-form
        v-if="jobDataInner.type === 2"
        ref="nominationForm"
        :model="nominationFormData"
        label-position="top"
        :disabled="[3,4,5].includes(jobDataInner.status)"
        :validate-on-rule-change="false">
        <div class="contract-content">
          <div class="fields">
            <div class="classification">
              <h1 id="ex-wharf" class="title">Nomination</h1>
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-button
                    style="width: 140px;float: right"
                    type="primary"
                    round
                    size="mini"
                    @click="emailVisible=true">
                    Send Emails
                  </el-button>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="Estimated Loading Date">
                    <el-date-picker
                      v-model="nominationFormData.estimatedLoadingTime"
                      @change="(e) => nominationFormData.estimatedLoadingTime = e ? e.toISOString() : null"
                      type="datetime"
                      :placeholder="$t('selectDate')">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item
                    label="Operational Requirement"
                    prop="orderMode">
                    <el-radio-group v-model="nominationFormData.operationalRequirement">
                      <el-radio :label="0">Not required</el-radio>
                      <el-radio :label="1">Arrival</el-radio>
                      <el-radio :label="2">Departure</el-radio>
                      <el-radio :label="3">Arrival and Departure</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="Is Pilot Required">
                    <el-radio-group v-model="nominationFormData.pilotRequired">
                      <el-radio :label="true">Yes</el-radio>
                      <el-radio :label="false">No</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="Is Tugboat Required">
                    <el-radio-group v-model="nominationFormData.tugboatRequired">
                      <el-radio :label="true">Yes</el-radio>
                      <el-radio :label="false">No</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    label="Please indicate how many"
                    prop="purchaseOrderNumber">
                    <el-input-number
                      v-model="nominationFormData.numberTugboatRequired"
                      :disabled="!nominationFormData.tugboatRequired"
                      :min="1"
                      :max="5">
                    </el-input-number>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <div class="cost">
                    <p>Barge Information</p>
                    <div>
                      <el-button
                        style="width: 180px"
                        type="primary"
                        round
                        size="mini"
                        @click="addBarge">
                        Add Barge Information
                      </el-button>
                    </div>
                  </div>
                </el-col>
                <div class="product-container product">
                  <div class="single-row-container">
                    <div
                      v-for="(item,index) in nominationFormData.bargeInfo"
                      :key="index">
                      <el-form-item
                        style="min-width: 300px"
                        :label="index===0?'Barge Name ':''">
                        <el-select
                          filterable
                          clearable
                          class="single-border"
                          style="width: 100%"
                          v-model="item.bargeName"
                          @change="bargeNameChangeHandler($event,item)">
                          <el-option
                            v-for="item in sortByAlphabetical(currentOperaterOrgShipList,'shipName')"
                            :value="item.shipName"
                            :label="item.shipName"
                            :disabled="!!nominationFormData.bargeInfo.find(item1=>item1.id===item.id)"
                            :key="item.id">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item
                        style="min-width: 260px"
                        :label="index===0?'Barge Contact':''"
                        class="single-border">
                        <el-input v-model="item.bargeContact"></el-input>
                      </el-form-item>
                      <el-form-item
                        style="min-width: 260px"
                        class="single-border"
                        :label="index===0?'SB No.':''">
                        <el-input v-model="item.sbNo"></el-input>
                      </el-form-item>
                      <el-form-item
                        style="min-width: 200px"
                        class="single-border"
                        :label="index===0?'Loading Date and Time':''">
                        <el-date-picker
                          style="width: 100%"
                          v-model="item.loadingDateTime"
                          @change="(e) => item.loadingDateTime = e ? e.toISOString() : null"
                          type="datetime"
                          :placeholder="$t('selectDate')">
                        </el-date-picker>
                      </el-form-item>
                      <el-form-item
                        style="min-width: 300px"
                        :label="index===0?'Remarks':''">
                        <el-input v-model="item.remarks"></el-input>
                      </el-form-item>
                    </div>
                  </div>
                  <div class="delete-btn-block">
                    <div
                      v-for="(item,index) in nominationFormData.bargeInfo"
                      :key="index">
                      <el-button
                        class="delete-btn-item"
                        type="danger"
                        size="mini"
                        icon="el-icon-close"
                        circle
                        @click="deleteBarge(index)">
                      </el-button>
                    </div>
                  </div>
                </div>
                <el-col :span="24">
                  <el-form-item
                    label="Remarks for Email Recipient (If applicable)"
                    prop="purchaseOrderNumber">
                    <el-input
                      type="textarea"
                      :rows="4"
                      v-model="nominationFormData.nominationRemark">
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-form> -->
      <!-- ex-wharf Schedule, exWharfScheduleFormData-->
      <el-form
        v-if="jobDataInner.type === 2"
        ref="exWharfScheduleForm"
        :model="exWharfScheduleFormData"
        :disabled="[3,4,5].includes(jobDataInner.status) || !isJobCreatorOrCustomerEditView"
        label-position="top"
        :validate-on-rule-change="false">
        <div class="contract-content">
          <div class="fields">
            <div class="classification">
              <h1 class="title">Scheduling</h1>
              <el-row :gutter="10">
                <el-col :sm="24" :md="8">
                  <el-form-item
                    label='Assigned Barge Name'
                    prop="bargeShipId">
                    <barge-or-vessel-selector
                      :disabled="jobDataInner.status !== 1 || !isJobCreatorOrCustomerEditView"
                      :schedulFormData="exWharfScheduleFormData"
                      :availableBargesLoading="false"
                      :availableBargesOptions="availableBargesOptionsInner"
                      @handleChangeBarge="handleChangeBarge"
                      @visibleChange="visibleChange"
                    />

                  </el-form-item>
                </el-col>
                <el-col :sm="24" :md="8">
                  <el-form-item
                    style="min-width: 260px"
                    label='Loading Date and Time'
                    class="single-border">
                    <el-date-picker
                      style="width: 100%"
                      v-model="exWharfScheduleFormData.loadingTime"
                      @change="(e) => exWharfScheduleFormData.loadingTime = e ? e.toISOString() : null"
                      type="datetime"
                      format="yyyy-MM-dd HH:mm"
                      :placeholder="$t('selectDate')">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :sm="24" :md="8">
                  <el-form-item
                    style="min-width: 260px"
                    class="single-border"
                    label='Loading Quantity'>
                    <el-input v-model.number="exWharfScheduleFormData.loadingQty">
                      <el-select
                        slot="append"
                        style="width: 120px"
                        v-model="exWharfScheduleFormData.qtyUnitMetric" disabled>
                        <el-option value="MT">MT</el-option>
                        <el-option value="CBM">CBM</el-option>
                        <el-option value="BARRELS">BARRELS</el-option>
                        <el-option value="%">%</el-option>
                      </el-select>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :sm="24" :md="8">
                  <el-form-item label="Location">
                    <el-radio-group v-model="exWharfScheduleFormData.exWharfType" @change="changeExwharfType">
                      <el-radio :label="0">Terminal</el-radio>
                      <el-radio :label="1">Floater</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :sm="24" :md="8">
                  <el-form-item
                    v-if="exWharfScheduleFormData.exWharfType === 1"
                    prop="floaterName"
                    label="Floater Name">
                  <el-input
                    v-model="exWharfScheduleFormData.floaterName"
                    style="width: 100%"
                    :disabled = prodNameDisabled>
                  </el-input>
                </el-form-item>
                </el-col>
                <el-col :sm="24" :md="8">
                  <!-- locationList exWharfScheduleFormData -->
                  <!-- <el-form-item
                    style="min-width: 260px"
                    class="single-border"
                    :label="exWharfScheduleFormData.exWharfType === 1 ? 'Floater Name' : 'Terminal'">
                    <el-input
                      style="width: 100%"
                      v-model="exWharfScheduleFormData.locationLevel1"
                      v-if="exWharfScheduleFormData.exWharfType === 1"
                    />
                      <el-select
                      v-else
                      filterable
                      clearable
                      style="width: 100%"
                      @change="handleTerminalChange()"
                      v-model="exWharfScheduleFormData.locationLevel1">
                      <el-option
                      v-for="item in locationList"
                      :key="item.name"
                      :label="item.name"
                      :value="item.name">
                    </el-option>
                    </el-select>
                  </el-form-item> -->
                  <el-form-item
                    prop="locationLevel"
                    label="Anchorage/Berth">
                    <el-cascader
                      clearable
                      popper-class="el-cascader-long"
                      style="width:100%;"
                      v-model="exWharfScheduleFormData.locationLevel"
                      :options="locationList"
                      :props="locationOptionProps">
                    </el-cascader>
                  </el-form-item>
                </el-col>
                <!-- <el-col :sm="24" :md="8">
                  <el-form-item
                    prop="locationLevel2"
                    :rules="(exWharfScheduleFormData.exWharfType === 1 || !exWharfScheduleFormData.locationLevel1) ? null : rules.reqRule"
                    style="min-width: 260px"
                    class="single-border"
                    :label="exWharfScheduleFormData.exWharfType === 1 ? 'Location' : 'Anchorage/Berth'">
                    <el-input
                      style="width: 100%"
                      v-model="exWharfScheduleFormData.locationLevel2"
                      v-if="exWharfScheduleFormData.exWharfType === 1"
                    />
                    <el-select
                      style="width: 100%"
                      v-else
                      filterable
                      clearable
                      v-model="exWharfScheduleFormData.locationLevel2"
                      :disabled="!exWharfScheduleFormData.locationLevel1 || !isJobCreatorOrCustomerEditView"
                      >
                      <el-option
                        v-for="item in berthList"
                        :key="item.name"
                        :label="item.name"
                        :value="item.name">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col> -->
                <el-col  :sm="24" :md="8">
                  <el-form-item
                    prop="fuelTypeCode"
                    label="Fuel Type (For MPA Reporting)"
                    :rules="[{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }]"
                  >
                    <el-select
                      v-model="exWharfScheduleFormData.fuelTypeCode"
                      style="width: 100%"
                      placeholder="Select"
                      @visible-change="getMpaFuelTypeListSortByRecent"
                      :loading="!exWharfScheduleFormData.length>0"
                    >
                      <el-option
                        v-for="item in mpaFuelTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col  :sm="24" :md="8">
                  <el-form-item
                    label="Reference No.">
                    <el-input v-model="exWharfScheduleFormData.referenceNo"/>
                  </el-form-item>
                </el-col>

              </el-row>
              <fuel-type-related-information :schedulFormData.sync="exWharfScheduleFormData" />
              <el-row :gutter="10">
                <el-col :sm="24" :md="24">
                  <el-form-item
                    label="Remarks">
                    <el-input
                        type="textarea"
                        :rows="2"
                        v-model="exWharfScheduleFormData.remark">
                    </el-input>
                    </el-form-item>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </el-form>
      <!--Vessel/delivered Schedule, deliveredScheduleFormData-->
      <el-form
        v-if="jobDataInner.type === 1"
        ref="deliveredScheduleForm"
        :model="deliveredScheduleFormData"
        :disabled="[3,4,5].includes(jobDataInner.status) || !isJobCreatorOrCustomerEditView"
        label-position="top"
        :validate-on-rule-change="false">

        <div class="classification">
          <h1 id="delivered" class="title">Supply Schedule</h1>
          <el-row :gutter="10">
            <el-col :sm="24" :md="8">
              <el-form-item
                label='Assigned Barge Name'
                prop="bargeShipId">
                <barge-or-vessel-selector
                  :disabled="jobDataInner.status !== 1 || !isJobCreatorOrCustomerEditView"
                  :schedulFormData="deliveredScheduleFormData"
                  :availableBargesLoading="false"
                  :availableBargesOptions="availableBargesOptionsInner"
                  @handleChangeBarge="handleChangeBarge"
                  @visibleChange="visibleChange"
                />

              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8">
              <el-form-item
                label='Vessel ETA'>
                <el-date-picker
                  style="width: 100%"
                  v-model="deliveredScheduleFormData.vesselEta"
                  @change="(e) => deliveredScheduleFormData.vesselEta = e ? e.toISOString() : null"
                  type="datetime"
                  format="yyyy-MM-dd HH:mm"
                  :placeholder="$t('selectDate')">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8">
              <el-form-item
                label='Supply Location'>
                <el-cascader
                  clearable
                  style="width: 100%"
                  v-model="supplyLocation"
                  :options="locationList"
                  :props="locationOptionProps"
                  :show-all-levels="false"
                  @change="supplyLocationChange">
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8">
              <el-form-item prop="supplyType" label="Supply Type" :rules="[{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }]">
                <el-select
                  v-model="deliveredScheduleFormData.supplyType"
                  style="width: 100%"
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in SUPPLY_TYPE_OPTIONS"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          <!-- </el-row>
          <el-row :gutter="10"> -->

            <el-col :sm="24" :md="8" >
              <el-form-item :label="nominationReferenceLabel">
                <el-input v-model="deliveredScheduleFormData.nominationReference"/>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8">
              <el-form v-if="jobDataInner.status == 4 && isJobCreatorOrCustomerEditView">
                <el-form-item
                  label="Reference No.">
                  <el-input v-model="deliveredScheduleFormData.referenceNo"/>
                </el-form-item>
              </el-form>
              <el-form-item v-else
                label="Reference No.">
                <el-input v-model="deliveredScheduleFormData.referenceNo"/>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="8" >
              <el-form-item label="Final Quantity (MT)">
                <el-input  type="number" v-model="deliveredScheduleFormData.finalQty"  class="input-with-select">

                </el-input>
              </el-form-item>
            </el-col>
            <el-col v-if="deliveredScheduleFormData.finalQtyLiter != null" :sm="24" :md="8" >
              <el-form-item label="Final Quantity (Litres)">
                <el-input  type="number" v-model="deliveredScheduleFormData.finalQtyLiter"  class="input-with-select" disabled>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col  :sm="24" :md="8">
              <el-form-item
                prop="fuelTypeCode"
                label="Fuel Type (For MPA Reporting)"
                :rules="[{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }]"
              >
                <el-select
                  v-model="deliveredScheduleFormData.fuelTypeCode"
                  style="width: 100%"
                  placeholder="Select"
                   @visible-change="getMpaFuelTypeListSortByRecent"
                  :loading="!mpaFuelTypeOptions.length>0"
                >
                  <el-option
                    v-for="item in mpaFuelTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <fuel-type-related-information :schedulFormData.sync="deliveredScheduleFormData" />
          <el-row :gutter="10">
            <el-col :sm="24" :md="8">
              <el-form-item
                prop="numberOfBdn"
                label="No. Of BDN"

              >
                <el-select v-model="deliveredScheduleFormData.numberOfBdn" style="width:100%" @change="handleChangeNumberOfBdn">
                  <el-option value="1">1</el-option>
                  <el-option value="2">2</el-option>
                  <el-option value="3">3</el-option>
                </el-select>
              </el-form-item>
            </el-col>
              <el-col :sm="12" :md="8" v-if="isExxonMobil">
              <el-form-item prop="customerName" label="Customer Name" :rules="rules.reqRule">
                <el-input v-model="deliveredScheduleFormData.customerName" />
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="isExxonMobil ? 8 : 16">
              <el-form-item
                  label='Remarks'>
                  <el-input v-model="deliveredScheduleFormData.remark"></el-input>
                </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" v-if="deliveredScheduleFormData.bdnQuantities?.length > 1">
            <el-col :sm="24" :md="8" v-for="(item, index) in deliveredScheduleFormData.bdnQuantities">
              <el-form-item :prop="`bdnQuantities.${index}`" :label="`Bdn Quantity ${index + 1}`" :rules="rules.bdnQuantity">
                <el-input
                  v-model="deliveredScheduleFormData.bdnQuantities[index]"
                  :type="'number'"
                  :min="0"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <el-form>
        <base-detail-card title="Audit Trails" v-if="jobDataInner.type === 1">
          <audit-trails-information :jobId="jobData.id"/>
        </base-detail-card>
        <base-detail-card title="MPA Transaction Information" v-if="jobData.status === 4"  :action="mpaInfoActions">
          <mpa-transaction-information
            :mpaTransactionData="mpaTransactionData"
          />
        </base-detail-card>
        <base-detail-card v-if="sgTradexState && jobData.status === 4 && sgTradexState.isSgTradexInfoVisible  && jobDataInner.type === 1" title="SgTradex Transaction Information" :action="sgTradexState.actions">
          <sg-tradex-transaction-information
            :sgTradexTransactionData.sync="sgTradexState.sgTradexTransactionData"
            :loading.sync="sgTradexState.sgTradexTransactionLoading"
          />
        </base-detail-card>
      </el-form>
       <br/>
      <el-divider/>
      <!--Order data, contractFormData-->
      <div style="display: flex; justify-content: space-between; padding-inline: 10px;">
        <h1>Order Details</h1>
        <el-button
          circle
          :icon="orderVisible ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
          style="height: fit-content; align-self: center;"
          @click="orderVisible=!orderVisible"
        />
      </div>
        <order-form
          v-if="orderVisible"
          :propsView="'VIEW_ORDER'"
          :orderType="orderType" isOrderTopBar isViewOnly isOps :propsTradeType="tradeType" :orderId="jobDataInner.orderId"
        />
      <el-divider v-if="!orderVisible"/>
    </div>
    <!-- 没有完成，确定是否需要该功能-->
    <submitted
      v-if="submittedVisible"
      :response-data="responseData"
      @closed="submittedVisible=false">
    </submitted>
    <!-- schedule-operation-bar中没有设计select-order，确定是否需要该功能-->
    <link-order-dialog
      :visible.sync="contractVisible"
      :initial-data="contractFormData.id?contractFormData:null"
      orderMode="spot"
      @getValue="getOrder">
    </link-order-dialog>
    <!-- 没有完成，viewAttachmentsHandler没有被调用，确定是否需要该功能-->
    <file-preview-list
      :visible.sync="attachmentsVisible"
      :order="contractFormData"
      :files="attachments">
    </file-preview-list>
    <emails
      v-if="emailVisible"
      :nominations.sync="nominationFormData"
      :order-id="contractFormData.id || undefined"
      @closed="emailVisible=false">
    </emails>
  </div>
</template>

<script>
import { HEADER_CELL_STYLE, CELL_STYLE, ORDER_TYPE, ORDER_TRADE_TYPE, SUPPLY_TYPE_OPTIONS,} from '@/constants.js'
import { mapState } from 'vuex'
import baseMixin from '@/mixins/base-mixin'
import baseTableMixin from '@/mixins/base-table-mixin'
import BaseDetailCard from '@/components/BaseDetailCard'
import Submitted from './Submitted'
import FilePreviewList from '@/components/FilePreviewList'
import LinkOrderDialog from '@/views/businessManager/exWharf/components/LinkOrderDialog'
import ScheduleOperationBar from './ScheduleOperationBar'
import Emails from './Emails'
import terminalOptions from '@/utils/terminal'
import { sortByAlphabetical, getTodayISOString } from '@/utils'
import OrderForm from '@/views/order/components/OrderForm'
import MpaTransactionInformation from './MpaTransactionInformation.vue'
import SgTradexTransactionInformation from './SgTradexTransactionInformation.vue';
import AuditTrailsInformation from './AuditTrailsInformation.vue'
import TimeSheetInformation from './TimeSheetInformation.vue';
import FuelTypeRelatedInformation from "./FuelTypeRelatedInformation.vue"
import BargeOrVesselSelector from './BargeOrVesselSelector.vue';
import JobFiles from "./JobFiles.vue";
import {  getJobFiles,updateReferenceNo } from "@/services/modules/ops.js";
import {  getShipByShipId } from "@/services/modules/comm.js";
export default {
  name: 'EditAutomaticSchedule',
  mixins: [baseMixin, baseTableMixin],
  components: {
    OrderForm,
    Submitted,
    FilePreviewList,
    LinkOrderDialog,
    ScheduleOperationBar,
    Emails,
    BaseDetailCard,
    MpaTransactionInformation,
    AuditTrailsInformation,
    SgTradexTransactionInformation,
    TimeSheetInformation,
    FuelTypeRelatedInformation,
    BargeOrVesselSelector,
    JobFiles,
  },
  props: {
    jobData: {
      type: Object,
      default: () => ({}),
      required: false
    },
    locationOptionProps: Object,
    availableBargesOptions: Array,
    availableBargesLoading: Boolean,
    mpaTransactionData: Array,
    hasSysAdminPermission: Boolean,
    sgTradexState: {
      type: Object,
      required: true
    }
  },
  data () {
    // 不在线的买卖双方

    // const offline = []
    const productFormData = {
      id: null,
      productName: '',
      sysProductId: '',
      sysProductSpecificationId: '',
      specs: '',
      minQty: '',
      maxQty: '',
      finalQty: '',
      finalQtyLiter: null,
      unitMetric: '',
      operationalTolerance: '+/-',
      operationalToleranceFig: '',
      unitsOperationalTolerance: 'MT',
      tbc: false,
      margin: null,
      physicalSupplierBdn: ''
    }
    const bargeInfoFormData = {
      id: null,
      imo: '',
      email: '',
      bargeName: '',
      bargeContact: '',
      sbNo: '',
      loadingDateTime: null,
      remarks: '',
      ultimately: false
    }
    const nominationFormData = {
      id: '',
      estimatedLoadingTime: null,
      operationalRequirement: 0,
      pilotRequired: true,
      tugboatRequired: true,
      numberTugboatRequired: null,
      bargeInfo: [
        { ...bargeInfoFormData }
      ],
      nominationEmails: '',
      nominationRemark: ''
    }
    const exWharfScheduleFormData = {
      buyer: '',
      seller: '',
      locationLevel1: '',
      locationLevel2: '',
      bargeShipId: '',
      bargeImo: '',
      // bargeLicenceNo: '',
      bargeName: '',
      qtyUnitMetric: 'MT',
      loadingQty: 0,
      loadingTime: getTodayISOString(),
      remark: '',
      referenceNo: '',
      // 以下是冗余的字段
      exWharfType: 0,
      hasSurveyor: null,
      mitigatingCompanyOrgId: '',
      bargeOrgId: '',
      port: '',
      qty: null,
      minQty: null,
      maxQty: null,
      finalQty: null,
      finalQtyLiter: null,
      toleranceFig: null,
      toleranceFigUnit: 'MT',
      productName: '',
      specs: '',
      sysProductId: '',
      sysProductSpecificationId: '',
      stemStartDate: this.jobData.stemStartDate,
      stemEndDate: this.jobData.stemEndDate,
      fuelTypeCode: null,
      blendRatio: null,
      biofuelGenCode: null,
      biofuelISCC: null,
      biofuelISCCValue: null,
      biofuelCertScheme: null,
      typeOfFeedstock: null,
  }
    const deliveredScheduleFormData = {
      buyer: '',
      seller: '',
      vesselShipId: '',
      vesselLicenseNo: '',
      vesselName: '',
      vesselImo: '',
      vesselEta: '',
      locationLevel1: '',
      locationLevel2: '',
      remark: '',
      referenceNo: '',
      // 以下是冗余的字段
      hasSurveyor: false,
      bargeName: '',
      bargeImo: '',
      bargeShipId: '',
      // bargeLicenceNo: '',
      mitigatingCompanyOrgId: '',
      port: '',
      qtyUnitMetric: 'MT',
      minQty: '',
      maxQty: '',
      finalQty: '',
      finalQtyLiter: null,
      toleranceFig: '',
      toleranceFigUnit: 'MT',
      productName: '',
      specs: '',
      sysProductId: '',
      sysProductSpecificationId: '',
      bargeSealVerificationReportId: '',
      bargeOrgId: '',
      agentCompany: '',
      agentContact: '',
      surveyorCompany: '',
      surveyorContact: '',
      nominationReference: '',
      stemStartDate: this.jobData.stemStartDate,
      stemEndDate: this.jobData.stemEndDate,
      supplyType: '',
      numberOfBdn: '1',
      fuelTypeCode: '',
      blendRatio: null,
      biofuelGenCode: null,
      biofuelISCC: null,
      biofuelISCCValue: null,
      biofuelCertScheme: null,
      typeOfFeedstock: null,
      customerName: null,
      bdnQuantities: [],
    }
    const deliveredInfoFormData = {
      productName: '',
      sysProductId: '',
      sysProductSpecificationId: '',
      BargeAcceptedTime: '',
      etc: '',
      ebdnNo: '',
      ebdnDate: '',
      deliveredFigures: '',
      remarks: ''
    }
    const loadingInfoFormData = {
      productName: '',
      sysProductId: '',
      sysProductSpecificationId: '',
      nominationDate: null,
      bargeName: '',
      bargeAssignedDate: '',
      etc: '',
      cqNumber: '',
      cqDate: '',
      cqReceivedQty: null,
      remarks: ''
    }
    // 合同的字段
    const contractFormData = {
      id: null,
      orderNumber: '',
      mitigatingCompanyOrgId: '',
      purchaseOrderNumber: '',
      salesOrderNumber: '',
      orderMode: 'spot',
      buyerAssContractIds: [],
      buyerAssContracts: [],
      sellerAssContractIds: [],
      sellerAssContracts: [],
      orderType: null,
      // ex-wharf的字段
      terminal: '',
      dateRangeStart: '',
      dateRangeEnd: '',
      // delivered字段
      agent: {
        company: '',
        contact: '',
        email: '',
        pic: ''
      },
      surveyor: {
        company: '',
        contact: '',
        email: '',
        pic: ''
      },
      deliveredLiftingDetail: {
        id: null,
        dateRangeEnd: '',
        dateRangeStart: '',
        port: '',
        termsConditions: "Basis seller's terms and conditions unless specified",
        vessel: '',
        imo: ''
      },
      // delivered字段end
      buyerCompany: null,
      buyer: '',
      buyerName: '',
      buyerPhoneNo: '',
      buyerEmail: '',
      buyerPaymentTerm: null,
      buyerCreditTerms: 30,
      buyerGreenlightSupply: false,
      buyerGstApplicable: false,
      buyerRemark: '',
      sellerCompany: null,
      seller: '',
      sellerName: '',
      sellerPhoneNo: '',
      sellerEmail: '',
      sellerPaymentTerm: null,
      sellerCreditTerms: 30,
      sellerGreenlightSupply: false,
      sellerGstApplicable: false,
      sellerRemark: '',
      // offline,
      products: [
        { ...productFormData }
      ],
      loadingInformation: []
    }
    const rules = {
      reqRule: [{ required: true, message: 'This field is required', trigger: ['change', 'blur'] }]
    }
    return {
      HEADER_CELL_STYLE,
      CELL_STYLE,
      ORDER_TYPE,
      ORDER_TRADE_TYPE,
      SUPPLY_TYPE_OPTIONS,
      tradeType: null,
      jobDataInner: {},
      vessels: [],
      buyerLinkToTerm: false,
      sellerLinkToTerm: false,
      contractVisible: false,
      buyerUserList: [],
      currentOperaterOrgShipList: [],
      sellerUserList: [],
      assContracts: [],
      productFormData,
      contractFormData,
      bargeInfoFormData,
      nominationFormData,
      exWharfScheduleFormData,
      deliveredScheduleFormData,
      loadingInfoFormData,
      deliveredInfoFormData,
      rules,
      validateMessage: 'This field is required',
      productOptions: Object.freeze(
        [
          'MGO DMA 1.5%S MAX',
          'MGO DMA 0.5%S MAX',
          'LSMGO DMA 0.5%S MAX',
          'LSMGO DMA 0.1%S MAX',
          'HSFO 3.5% S MAX RME180',
          'HSFO 3.5% S MAX RMG380',
          'HSFO 3.5% S MAX RMK500',
          'VLSFO 0.5% S MAX RME180',
          'VLSFO 0.5% S MAX RMG380',
          'ULSFO 0.1% S MAX RME180'
        ]
      ),
      specsOptions: Object.freeze(
        [
          'ISO 8217:2005',
          'ISO 8217:2010',
          'ISO 8217:2012',
          'ISO 8217:2017'
        ]
      ),
      creditTermsPlaceholder: '',
      creditTermsOptions: Object.freeze(
        [
          {
            label: 'Credit Terms',
            value: 'Credit Terms'
          },
          {
            label: 'Cash In Advance',
            value: 'Cash In Advance'
          },
          {
            label: 'Cash On Delivery',
            value: 'Cash On Delivery'
          }
        ]
      ),
      submittedVisible: false,
      responseData: {},
      attachmentsVisible: false,
      attachments: [],
      // 合同类型,可选值：purchase,sales,purchase&sales
      contractType: 'purchase',
      orderVisible: false,
      emailVisible: false,
      terminalOptions: Object.freeze(terminalOptions),
      exWharfScheduleBerthOptions: [],
      nominationBerthOptions: [],
      dateRange: [],
      locationProps: {
        emitPath: true,
        label: 'value'
      },
      // 当前登录企业在本订单中的角色，创建方CREATOR/非创建方NON_CREATOR
      currentCompanyTraderType: 'CREATOR', // 是否需要保留
      realContractType: null,
      orderType: null,
      supplyLocation: [],
      mpaInfoActions: null,
      mpaFuelTypeOptions: [],
      isExxonMobil: false,
      jobFiles: {},
    }
  },

  computed: {
    ...mapState({
      currentCompany: 'currentCompany',
      locationList: 'locationList',
      mpaFuelTypeList: "mpaFuelTypeList",
      productList: "productList",
    }),
    availableBargesOptionsInner () {
      // const list = this.availableBargesOptions
      // const index = this.availableBargesOptions.findIndex(barge => (barge?.id && barge.id === this.jobDataInner?.bargeShipId) || (barge?.imo && barge.imo === this.jobDataInner?.bargeImo))
      // if (index === -1) {
      //   const prevShip = await getShipByShipId(this.jobDataInner.bargeShipId)
      //   // const prevShip = this.shipList?.find(barge =>(barge?.id && barge.id === this.jobDataInner?.bargeShipId) || (barge?.imo && barge.imo === this.jobDataInner?.bargeImo))
      //   if(prevShip) list.push(prevShip)
      // }
      return this.availableBargesOptions
    },
    berthList () {
      if (this.exWharfScheduleFormData.locationLevel1) {
        const list = this.locationList.filter(t => t.name === this.exWharfScheduleFormData.locationLevel1)[0]
        if (list) {
          return list.children
        } else {
          return []
        }
      } else {
        return []
      }
    },
    isJobCreatorOrCustomerEditView () {
      /// only job creator can edit
      return this.currentCompany.id === this.jobDataInner.mitigatingCompanyOrgId;
    },
    nominationReferenceLabel() {
      return this.isExxonMobil ? "SAP Order Number" : "Nomination Reference"
    },
  },

  created () {
    this.getByIdUrl = this.$apis.orders
    Object.keys(this.jobData).forEach(key => {
      this.$set(this.jobDataInner, key, this.jobData[key])

    })
    this.nominationFormData.id = this.jobDataInner.id
    this.tradeType = this.jobDataInner.seller === this.currentCompany.companyName ? ORDER_TRADE_TYPE.SALES : ORDER_TRADE_TYPE.PURCHASE
    if (this.jobDataInner.type === 1) {
      // type值是1则是delivered
      this.orderType = this.jobDataInner.type === 1 ? ORDER_TYPE.DELIVERED : ORDER_TYPE.BARGING_NOMINATION
      this.deliveredScheduleFormData.id = this.jobDataInner.id
      Object.keys(this.deliveredScheduleFormData).forEach(key => {
        this.$set(this.deliveredScheduleFormData, key, this.jobDataInner[key])
      })
      const { locationLevel1, locationLevel2 } = this.jobDataInner
      if (locationLevel1 && locationLevel2) {
        this.supplyLocation = [locationLevel1, locationLevel2]
      }
      if (!this.jobDataInner?.hasSurveyor && !!(this.jobDataInner?.surveyorCompany || this.jobDataInner?.surveyorEmail || this.jobDataInner?.surveyorContact)) {
        this.jobDataInner.hasSurveyor = true
      }

    } else if (this.jobDataInner.type === 2) {
      // type值是2则是ex-wharf
      this.orderType = ORDER_TYPE.EX_WHARF
      Object.keys(this.nominationFormData).forEach(key => {
        if (key !== 'bargeInfo') {
          this.$set(this.nominationFormData, key, this.jobDataInner[key])
        } else if (this.jobDataInner.bargeInfo) {
          this.$set(this.nominationFormData, 'bargeInfo', JSON.parse(this.jobDataInner.bargeInfo))
        }
      })
      this.exWharfScheduleFormData.id = this.jobDataInner.id

      Object.keys(this.exWharfScheduleFormData).forEach(key => {
        this.$set(this.exWharfScheduleFormData, key, this.jobDataInner[key])
      })
    }
    this.getJobInfoByJobId(this.jobDataInner.id)
    this.judgeTrader()
    this.getShipListByCompanyId(this.$store.state.currentCompany.id)

    if(this.jobDataInner.type==1) {
      this.handleChangeBDN(this.jobDataInner.bargeOrgId)
    }
    if ([3, 4].includes(this.jobData.status)) {
        getJobFiles(this.jobData.id).then((res) => {
          this.jobFiles = res.data;
        });
      }

  },
  destroyed () {
    this.$emit('onClearInterval')
  },
  methods: {
    sortByAlphabetical,

    getJobInfoByJobId (jobId) {
      const url = `${this.$apis.createNewJob}/${jobId}/info`
      this.$request.get({
        url
      }).then(res => {
        if (res?.code === 1000) {
          const data = res.data
          this.jobDataInner = {
            ...this.jobDataInner,
            docDate: data.docDate,
            docNumber: data.docNumber,
            docQty: data.docQty,
            etc: data.etc,
            bdnPdfId: data.bdnPdfId,
            coqPdfId: data.coqPdfId,
            cqPdfId: data.cqPdfId,
            mpaPdfId: data.mpaPdfId,
            timesheet :data.timesheet
          }
          if (this.jobDataInner.mpaPdfId) {
            this.mpaInfoActions = {
              label: 'View EBDN File',
              onAction: () => {
                this.previewFileMixin(this.jobDataInner.mpaPdfId)
              }
            }
          }
        }
      })
    },
    goBack () {
      // this.$refs.operationBar.loading = false
      this.$emit('close')
    },
    /**
     * 判断当前用户是创建者还是非创建者
     */
    judgeTrader () {
      if (this.contractFormData.id) {
        const { mitigatingCompanyOrgId } = this.contractFormData
        const { id: currentId } = this.currentCompany
        // 当前登录账号是创建方
        this.currentCompanyTraderType = currentId === mitigatingCompanyOrgId ? 'CREATOR' : 'NON_CREATOR'
      }
    },

    /**
     * 监听supplyLocation变化，动态设置berthOptions的值
     */
    supplyLocationChange (value) {
      this.deliveredScheduleFormData.locationLevel1 = value[0]
      this.deliveredScheduleFormData.locationLevel2 = value[1]
    },

    terminalChange (value) {
      const { berth } = terminalOptions
      this.exWharfScheduleFormData.locationLevel2 = ''
      this.exWharfScheduleBerthOptions = berth[value]
    },

    /**
     * 获取船舶列表
     */
    getShipListByCompanyId (companyId = undefined) {
      this.$request.get({
        url: `${this.$apis.shipBaseUrl}?pageNumber=1&pageSize=999999` + (companyId ? `&organizationId=${companyId}` : '')
      }).then(res => {
        if (res?.code === 1000) {
          const records = res.data.records || []
          if (companyId) {
            // const vessel = this.contractFormData.deliveredLiftingDetail.vessel
            // this.vessels = records.filter(item => item.id !== vessel)
            this.vessels = records
            this.currentOperaterOrgShipList = records
          } else {
            this.currentOperaterOrgShipList = records
          }
        }
      })
    },

    // 获取订单数据
    getOrder (orderData) {
    },

    /**
     * 增加Barge
     */
    addBarge () {
      const barges = this.$_.cloneDeep(this.bargeInfoFormData)
      this.nominationFormData.bargeInfo.push(barges)
    },

    /**
     * 删除Barge
     */
    deleteBarge (index) {
      if (this.nominationFormData.bargeInfo.length > 1) {
        this.nominationFormData.bargeInfo.splice(index, 1)
      }
    },

    /**
     * 监听订单状态修改成功
     */
    bargeNameChangeHandler (value, barge) {
      const ship = this.currentOperaterOrgShipList.find(item => item.shipName === value)
      barge.id = ship.id
      barge.imo = ship.imo
      barge.sbNo = ship.licenceNo
      barge.bargeContact = ship.cellPhone
    },

    /**
     * 提交schedule的数据
     * job的类型，1: delivery 2: ex-wharf, 3: STS
     */
    submitScheduleData () {
      const _this = this
      const submit = (type) => {
        const form = {
          exWharf: {
            formName: 'exWharfScheduleForm',
            data: _this.exWharfScheduleFormData,
            url: _this.$apis.editExWharfJob
          },
          delivered: {
            formName: 'deliveredScheduleForm',
            data: _this.deliveredScheduleFormData,
            url: _this.$apis.editDeliveredJob
          }
        }


        this.$refs[form[type].formName].validate(valid => {
          if (valid) {
            this.$refs.operationBar.loading = true
            // this.$oneLoading.show('Saving...')
            this.$request.put({
              url: form[type].url,
              data: form[type].data
            }).then((res,reject )=> {
              if (res?.code === 1000) {
                Object.keys(form[type].data).forEach(key => {
                  this.jobDataInner[key] = res.data[key]
                })
                this.$message.success('Schedule has been submitted.')
                this.goBack()
              }
            }).catch(error => this.$refs.operationBar.loading = false).finally(() => {
              // this.$refs.operationBar.loading = false
              this.$oneLoading.hide()
            })
          }
        })
      }
      if (this.jobDataInner.type === 1) {
        submit('delivered')
      } else if (this.jobDataInner.type === 2) {
        submit('exWharf')
      } else {
        submit('sts')
      }
    },

    /**
     * 提交Nomination的数据
     */
    submitNominationData (hasMsg = true) {
      return new Promise(resolve => {
        this.$refs.nominationForm.validate(valid => {
          if (valid) {
            this.$refs.operationBar.loading = true
            const data = this.$_.cloneDeep(this.nominationFormData)
            let { bargeInfo } = data
            bargeInfo = bargeInfo.filter(item => !!item)
            data.bargeInfo = JSON.stringify(bargeInfo)
            this.$request.put({
              url: this.$apis.editNomination,
              data
            }).then(res => {
              if (res?.code === 1000) {
                if (hasMsg) {
                  Object.keys(this.nominationFormData).forEach(key => {
                    this.jobDataInner[key] = res.data[key]
                  })
                  this.goBack()
                  this.$message.success('Nomination has been submitted.')
                }
                resolve()
              }
            }).finally(() => {
              this.goBack()
              this.$refs.operationBar.loading = false
            })
          }
        })
      })
    },
    submitReferenceNo () {
      this.$refs.operationBar.loading = true
      updateReferenceNo(this.jobData.id,{referenceNumber: this.deliveredScheduleFormData.referenceNo}).then((resp) => {
        if (resp?.code === 1000) {
          this.$message.success(resp.message);
          this.$emit("close");
        } else {
          this.$message.error(resp.message);
        }
      })
      .finally(async () => {
        this.$refs.operationBar.loading = false
      });
    },

    /**
     * 比对数据，以便知道那些区域做了修改
     */
    comparisonData (type) {
      const _this = this
      const comparison = form => {
        for (const key in this[form]) {
          if (key === 'bargeInfo') {
            if (JSON.stringify(this[form][key]) !== this.jobDataInner[key]) {
              return true
            }
            continue
          }
          if (this[form][key] !== this.jobDataInner[key]) {
            return true
          }
        }
        return false
      }
      const map = {
        nominations: 'nominationFormData',
        schedule: (_this.jobDataInner.type === 1) ? 'deliveredScheduleFormData' : 'exWharfScheduleFormData'
      }
      return comparison(map[type])
    },

    /**
     * 提交Job数据
     */
    submitJobData () {
      /* Do not remove, might need in future */
      // let nominationChanged = this.comparisonData('nominations')
      // if (this.jobDataInner.type === 1) {
      //   nominationChanged = false
      // }
      const jobChanged = this.comparisonData('schedule')
      if (jobChanged) {
        this.$confirm('', 'Confirm Submit?', {
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning',
          center: true,
          roundButton: true
        }).then(() => {
          /* Do not remove, might need in future */
          // if (nominationChanged && jobChanged) {
          //   this.submitNominationData(false).then(() => {
          //     this.submitScheduleData()
          //   })
          // } else if (nominationChanged) {
          //   this.submitNominationData()
          // } else if (jobChanged) {
          //   this.submitScheduleData()
          // }
          if (jobChanged) {
            this.submitScheduleData()
          }
        }).catch(() => {})
      } else {
        this.$message.warning('No changes made.')
      }
    },

    /**
     * 监听文件预览,没有到暂时保留
     */
    viewAttachmentsHandler (attachments) {
      this.attachments = attachments
      this.attachmentsVisible = true
    },

    /**
     * 订单删除成功后回到列表页
     */
    deleteSuccessHandler () {
      this.$parent.createContractVisible = false
    },
    /**
     * 当类型改变是，改变ｌｏｃａｔｉｏｎ的输入方式
     */
    changeExwharfType (value) {
      this.exWharfScheduleFormData.locationLevel2 = ''
      this.exWharfScheduleFormData.locationLevel1 = ''
    },
    visibleChange (isVisit) {
      if (isVisit) {
        this.$emit('checkAvailableBargesOptions', true, this.jobData)
      }
    },
    handleTerminalChange (terminal) {
      if (!terminal) {
        this.exWharfScheduleFormData.locationLevel2 = null
        this.$refs.exWharfScheduleForm.clearValidate('locationLevel2')
      }
    },
    handleChangeBarge (val) {
      const shipItem = this.availableBargesOptionsInner.find(ship => ship?.id === val)

      if (this.jobDataInner.type === 2) {
        this.exWharfScheduleFormData.bargeShipId = val
        this.exWharfScheduleFormData.bargeName = shipItem?.shipName
        this.exWharfScheduleFormData.bargeImo = shipItem?.imo
        // this.exWharfScheduleFormData.bargeLicenceNo = shipItem?.licenceNo
      } else {
        this.deliveredScheduleFormData.bargeShipId = val
        this.deliveredScheduleFormData.bargeName = shipItem?.shipName
        this.deliveredScheduleFormData.bargeImo = shipItem?.imo
        // this.deliveredScheduleFormData.bargeLicenceNo = shipItem?.licenceNo
      }
    },
    getMpaFuelTypeListSortByRecent (isVisit) {
      if ( !isVisit ) return
      this.mpaFuelTypeOptions = [];
      const schedulFormData = this.jobDataInner.type === 2?this.exWharfScheduleFormData:this.deliveredScheduleFormData;

      const productItem =schedulFormData?.sysProductId ? this.productList.find(product=>product.id == schedulFormData?.sysProductId):null
      if (productItem?.oilType.trim()==''|| !productItem?.oilType) {
        this.mpaFuelTypeOptions = [];
      }else{
        this.$store.dispatch("getFilterMpaFuelTypeList",{oilType:productItem.oilType,shipId:schedulFormData.bargeShipId}).then((data) => {
          this.mpaFuelTypeOptions = data
        })
      }
    },
    async handleChangeBDN(bargeOrgId) {
      if(bargeOrgId) {
        await this.$store.dispatch("isExxonMobil", bargeOrgId).then(res => this.isExxonMobil = res)
      } else {
        this.isExxonMobil = false
        this.deliveredScheduleFormData.customerName = null
      }
    },
    handleChangeNumberOfBdn() {
      const numberOfBdn = this.deliveredScheduleFormData.numberOfBdn
      if( numberOfBdn == 1 ) {
        this.deliveredScheduleFormData.bdnQuantities = []
      } else {
        const length =  numberOfBdn - this.deliveredScheduleFormData.bdnQuantities.length
        for(var i = 0 ; i < Math.abs(length); i++) {
          if(length > 0) {
            this.deliveredScheduleFormData.bdnQuantities.push(0)
          } else {
            this.deliveredScheduleFormData.bdnQuantities = this.deliveredScheduleFormData.bdnQuantities.slice(0,-1)
          }
        }
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.create-contract-container {
  height: 100%;

  .form {
    height: calc(100% - 42px);

    .el-form {
      padding-right: 10px;
    }

    .contract-number {
      display: flex;
      justify-content: space-between;

      .num-item {
        display: inline-flex;
        flex-direction: column;
        margin: 0 30px 20px 0;

        /deep/ .el-input {
          margin-top: 10px;
          font-size: 18px;

          /deep/ input {
            color: $primary-color;
          }
        }

        .el-form-item {
          margin-bottom: 0;
        }
      }
    }

      /* basic-card-block */
      .classification {
        width: 100%;
        margin: 5px auto;

        p {
          margin-top: 0;
          font-weight: bold;
          color: #606266;
        }
        /* sub-module-title */
        .title {
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: $second-color-font;
          margin: 0;
          padding: 10px 10px 0 10px;
          width: fit-content;
          border-radius: 5px;
        }

        .product-container {
          width: 100%;
          display: flex;

          /deep/ input,
          /deep/ .el-date-editor,
          /deep/ textarea,
          /deep/ .el-input-group__append,
          /deep/ .el-input-group__prepend {
            border: none;
          }

          .delete-btn-block {
            margin-right: 20px;

            > div {
              display: flex;
              align-items: flex-end;
              height: 62px;

              &:first-child {
                height: 84px;
                margin-top: 20px;
              }
            }
          }
        }

        .single-row-container {
          width: calc(100% - 40px);
          box-sizing: border-box;
          overflow-x: auto;
          margin:0 10px;

          > div {
            display: flex;

            .el-form-item {
              min-width: 150px;
            }
            .delete-btn-block {
              position: absolute;
              right: -30px;
              bottom: 0;
            }
          }
        }

        .delete-btn-item {
          padding: 3px;
        }

        .el-row {
          padding:10px;

          .cost {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin: 10px 0;
          }
        }
      }

      .product {
        /deep/ input,
        /deep/ .el-date-editor,
        /deep/ textarea,
        /deep/ .el-input-group__append,
        /deep/ .el-input-group__prepend {
          border: none;
        }

        .single-border {
          /deep/ input {
            border-radius: 0;
            border-right: 1px solid #DCDFE680 !important;
          }
        }

        /deep/ .no-border {
          input {
            border: none !important;
          }
        }


      }

  }
}
.view-pdf {
  color: #315AEE;
  border-bottom: none;
}
.el-link.el-link--default{
  color: #315AEE;
}
.el-link.el-link--default:after, .el-link.el-link--primary.is-underline:hover:after, .el-link.el-link--primary:after{
  color: #315AEE;
  border-bottom: none;
}
.right-margin {
  margin-right: 15px;
}
/deep/.div-create-container {
  overflow: hidden !important;
  height: fit-content !important;
}
.order-form {
  /deep/.el-loading-spinner {
    top: 10%;
  }
}
.el-date-editor {
    width: 100%;
  }
</style>
