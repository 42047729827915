var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "create-contract-container" },
    [
      _c(
        "schedule-operation-bar",
        _vm._g(
          {
            ref: "operationBar",
            staticClass: "operation-bar",
            attrs: {
              "job-data": _vm.jobDataInner,
              canEdit: _vm.isJobCreatorOrCustomerEditView,
              hasSysAdminPermission: _vm.hasSysAdminPermission,
            },
            on: {
              "update:jobData": function ($event) {
                _vm.jobDataInner = $event
              },
              "update:job-data": function ($event) {
                _vm.jobDataInner = $event
              },
              "select-order": function ($event) {
                _vm.contractVisible = true
              },
              submit: _vm.submitJobData,
              submitReferenceNo: _vm.submitReferenceNo,
            },
          },
          _vm.$listeners
        )
      ),
      _c(
        "div",
        {
          staticClass: "form",
          staticStyle: { "overflow-y": "auto", height: "calc(100% - 42px)" },
        },
        [
          _vm.jobData.status && [3, 4].includes(_vm.jobData.status)
            ? _c(
                "base-detail-card",
                { attrs: { title: "Files" } },
                [
                  _c("job-files", {
                    attrs: { jobFiles: _vm.jobFiles },
                    on: {
                      "update:jobFiles": function ($event) {
                        _vm.jobFiles = $event
                      },
                      "update:job-files": function ($event) {
                        _vm.jobFiles = $event
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "base-detail-card",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: !_vm.jobDataInner,
                  expression: "!jobDataInner",
                },
              ],
              attrs: { title: "Delivered Information" },
            },
            [
              _vm.jobDataInner.type === 1
                ? _c(
                    "el-form",
                    {
                      ref: "deliveredScheduleForm",
                      attrs: {
                        model: _vm.deliveredInfoFormData,
                        "label-position": "top",
                        "validate-on-rule-change": false,
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Product Name" } },
                                [
                                  _c("el-input", {
                                    staticClass: "single-border",
                                    attrs: {
                                      readonly: "",
                                      value: (_vm.jobDataInner || {})
                                        .productName,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Barge Name" } },
                                [
                                  _c("el-input", {
                                    staticClass: "single-border",
                                    attrs: {
                                      readonly: "",
                                      value: (_vm.jobDataInner || {}).bargeName,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { label: "eBDN Date" },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "single-border",
                                    attrs: { type: "date", readonly: "" },
                                    model: {
                                      value: (_vm.jobDataInner || {}).docDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.jobDataInner || {},
                                          "docDate",
                                          $$v
                                        )
                                      },
                                      expression: "(jobDataInner||{}).docDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Delivered Quantity" } },
                                [
                                  _c("el-input", {
                                    staticClass: "single-border",
                                    attrs: {
                                      readonly: "",
                                      value: (_vm.jobDataInner || {}).docQty,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "ETC" } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "single-border",
                                    attrs: { type: "date", readonly: "" },
                                    model: {
                                      value: (_vm.jobDataInner || {}).etc,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.jobDataInner || {},
                                          "etc",
                                          $$v
                                        )
                                      },
                                      expression: "(jobDataInner||{}).etc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              (_vm.jobDataInner || {}).bdnPdfId
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "BDN Document" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: { type: "primary", plain: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.previewFileMixin(
                                                (_vm.jobDataInner || {})
                                                  .bdnPdfId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                (_vm.jobDataInner || {})
                                                  .docNumber
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.jobDataInner?.timesheet
                        ? _c("time-sheet-information", {
                            attrs: {
                              jobDataInner: _vm.jobDataInner,
                              jobData: _vm.jobData,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.jobDataInner.type === 2
            ? _c(
                "base-detail-card",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: !_vm.jobDataInner,
                      expression: "!jobDataInner",
                    },
                  ],
                  attrs: { title: "Loading Information" },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "loadingInfoForm",
                      attrs: {
                        model: _vm.loadingInfoFormData,
                        "label-position": "top",
                        "validate-on-rule-change": false,
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Product Name" } },
                                [
                                  _c("el-input", {
                                    staticClass: "single-border",
                                    attrs: {
                                      readonly: "",
                                      value: (_vm.jobDataInner || {})
                                        .productName,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Barge Name" } },
                                [
                                  _c("el-input", {
                                    staticClass: "single-border",
                                    attrs: {
                                      readonly: "",
                                      value: (_vm.jobDataInner || {}).bargeName,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { label: "eBDN Date" },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "single-border",
                                    attrs: { type: "date", readonly: "" },
                                    model: {
                                      value: (_vm.jobDataInner || {}).docDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.jobDataInner || {},
                                          "docDate",
                                          $$v
                                        )
                                      },
                                      expression: "(jobDataInner||{}).docDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Delivered Quantity" } },
                                [
                                  _c("el-input", {
                                    staticClass: "single-border",
                                    attrs: {
                                      readonly: "",
                                      value: (_vm.jobDataInner || {}).docQty,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "ETC" } },
                                [
                                  _c("el-date-picker", {
                                    staticClass: "single-border",
                                    attrs: { type: "date", readonly: "" },
                                    model: {
                                      value: (_vm.jobDataInner || {}).etc,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.jobDataInner || {},
                                          "etc",
                                          $$v
                                        )
                                      },
                                      expression: "(jobDataInner||{}).etc",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              (_vm.jobDataInner || {}).cqPdfId
                                ? _c(
                                    "el-form-item",
                                    { attrs: { label: "Documents" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: { type: "primary", plain: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.previewFileMixin(
                                                (_vm.jobDataInner || {}).cqPdfId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " [" +
                                              _vm._s(
                                                (_vm.jobDataInner || {})
                                                  .docNumber
                                              ) +
                                              "] CQ File "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8, lg: 4 } },
                            [
                              (_vm.jobDataInner || {}).cqPdfId
                                ? _c(
                                    "el-form-item",
                                    { staticStyle: { "margin-top": "18px" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: { type: "primary", plain: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.previewFileMixin(
                                                (_vm.jobDataInner || {})
                                                  .coqPdfId
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " [" +
                                              _vm._s(
                                                (_vm.jobDataInner || {})
                                                  .docNumber
                                              ) +
                                              "] COQ File "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.jobDataInner?.timesheet
                        ? _c("time-sheet-information", {
                            attrs: {
                              jobDataInner: _vm.jobDataInner,
                              jobData: _vm.jobData,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.jobDataInner.type === 2
            ? _c(
                "el-form",
                {
                  ref: "exWharfScheduleForm",
                  attrs: {
                    model: _vm.exWharfScheduleFormData,
                    disabled:
                      [3, 4, 5].includes(_vm.jobDataInner.status) ||
                      !_vm.isJobCreatorOrCustomerEditView,
                    "label-position": "top",
                    "validate-on-rule-change": false,
                  },
                },
                [
                  _c("div", { staticClass: "contract-content" }, [
                    _c("div", { staticClass: "fields" }, [
                      _c(
                        "div",
                        { staticClass: "classification" },
                        [
                          _c("h1", { staticClass: "title" }, [
                            _vm._v("Scheduling"),
                          ]),
                          _c(
                            "el-row",
                            { attrs: { gutter: 10 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { sm: 24, md: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Assigned Barge Name",
                                        prop: "bargeShipId",
                                      },
                                    },
                                    [
                                      _c("barge-or-vessel-selector", {
                                        attrs: {
                                          disabled:
                                            _vm.jobDataInner.status !== 1 ||
                                            !_vm.isJobCreatorOrCustomerEditView,
                                          schedulFormData:
                                            _vm.exWharfScheduleFormData,
                                          availableBargesLoading: false,
                                          availableBargesOptions:
                                            _vm.availableBargesOptionsInner,
                                        },
                                        on: {
                                          handleChangeBarge:
                                            _vm.handleChangeBarge,
                                          visibleChange: _vm.visibleChange,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 24, md: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "single-border",
                                      staticStyle: { "min-width": "260px" },
                                      attrs: { label: "Loading Date and Time" },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          type: "datetime",
                                          format: "yyyy-MM-dd HH:mm",
                                          placeholder: _vm.$t("selectDate"),
                                        },
                                        on: {
                                          change: (e) =>
                                            (_vm.exWharfScheduleFormData.loadingTime =
                                              e ? e.toISOString() : null),
                                        },
                                        model: {
                                          value:
                                            _vm.exWharfScheduleFormData
                                              .loadingTime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.exWharfScheduleFormData,
                                              "loadingTime",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "exWharfScheduleFormData.loadingTime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 24, md: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      staticClass: "single-border",
                                      staticStyle: { "min-width": "260px" },
                                      attrs: { label: "Loading Quantity" },
                                    },
                                    [
                                      _c(
                                        "el-input",
                                        {
                                          model: {
                                            value:
                                              _vm.exWharfScheduleFormData
                                                .loadingQty,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.exWharfScheduleFormData,
                                                "loadingQty",
                                                _vm._n($$v)
                                              )
                                            },
                                            expression:
                                              "exWharfScheduleFormData.loadingQty",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "120px" },
                                              attrs: {
                                                slot: "append",
                                                disabled: "",
                                              },
                                              slot: "append",
                                              model: {
                                                value:
                                                  _vm.exWharfScheduleFormData
                                                    .qtyUnitMetric,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.exWharfScheduleFormData,
                                                    "qtyUnitMetric",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "exWharfScheduleFormData.qtyUnitMetric",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-option",
                                                { attrs: { value: "MT" } },
                                                [_vm._v("MT")]
                                              ),
                                              _c(
                                                "el-option",
                                                { attrs: { value: "CBM" } },
                                                [_vm._v("CBM")]
                                              ),
                                              _c(
                                                "el-option",
                                                { attrs: { value: "BARRELS" } },
                                                [_vm._v("BARRELS")]
                                              ),
                                              _c(
                                                "el-option",
                                                { attrs: { value: "%" } },
                                                [_vm._v("%")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 10 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { sm: 24, md: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Location" } },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          on: { change: _vm.changeExwharfType },
                                          model: {
                                            value:
                                              _vm.exWharfScheduleFormData
                                                .exWharfType,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.exWharfScheduleFormData,
                                                "exWharfType",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "exWharfScheduleFormData.exWharfType",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 0 } },
                                            [_vm._v("Terminal")]
                                          ),
                                          _c(
                                            "el-radio",
                                            { attrs: { label: 1 } },
                                            [_vm._v("Floater")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-row",
                            { attrs: { gutter: 10 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { sm: 24, md: 8 } },
                                [
                                  _vm.exWharfScheduleFormData.exWharfType === 1
                                    ? _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop: "floaterName",
                                            label: "Floater Name",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              disabled: _vm.prodNameDisabled,
                                            },
                                            model: {
                                              value:
                                                _vm.exWharfScheduleFormData
                                                  .floaterName,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.exWharfScheduleFormData,
                                                  "floaterName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "exWharfScheduleFormData.floaterName",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 24, md: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "locationLevel",
                                        label: "Anchorage/Berth",
                                      },
                                    },
                                    [
                                      _c("el-cascader", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          clearable: "",
                                          "popper-class": "el-cascader-long",
                                          options: _vm.locationList,
                                          props: _vm.locationOptionProps,
                                        },
                                        model: {
                                          value:
                                            _vm.exWharfScheduleFormData
                                              .locationLevel,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.exWharfScheduleFormData,
                                              "locationLevel",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "exWharfScheduleFormData.locationLevel",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 24, md: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "fuelTypeCode",
                                        label: "Fuel Type (For MPA Reporting)",
                                        rules: [
                                          {
                                            required: true,
                                            message: "This field is required",
                                            trigger: ["blur", "change"],
                                          },
                                        ],
                                      },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placeholder: "Select",
                                            loading:
                                              !_vm.exWharfScheduleFormData
                                                .length > 0,
                                          },
                                          on: {
                                            "visible-change":
                                              _vm.getMpaFuelTypeListSortByRecent,
                                          },
                                          model: {
                                            value:
                                              _vm.exWharfScheduleFormData
                                                .fuelTypeCode,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.exWharfScheduleFormData,
                                                "fuelTypeCode",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "exWharfScheduleFormData.fuelTypeCode",
                                          },
                                        },
                                        _vm._l(
                                          _vm.mpaFuelTypeOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { sm: 24, md: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Reference No." } },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value:
                                            _vm.exWharfScheduleFormData
                                              .referenceNo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.exWharfScheduleFormData,
                                              "referenceNo",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "exWharfScheduleFormData.referenceNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c("fuel-type-related-information", {
                            attrs: {
                              schedulFormData: _vm.exWharfScheduleFormData,
                            },
                            on: {
                              "update:schedulFormData": function ($event) {
                                _vm.exWharfScheduleFormData = $event
                              },
                              "update:schedul-form-data": function ($event) {
                                _vm.exWharfScheduleFormData = $event
                              },
                            },
                          }),
                          _c(
                            "el-row",
                            { attrs: { gutter: 10 } },
                            [
                              _c(
                                "el-col",
                                { attrs: { sm: 24, md: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "Remarks" } },
                                    [
                                      _c("el-input", {
                                        attrs: { type: "textarea", rows: 2 },
                                        model: {
                                          value:
                                            _vm.exWharfScheduleFormData.remark,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.exWharfScheduleFormData,
                                              "remark",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "exWharfScheduleFormData.remark",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.jobDataInner.type === 1
            ? _c(
                "el-form",
                {
                  ref: "deliveredScheduleForm",
                  attrs: {
                    model: _vm.deliveredScheduleFormData,
                    disabled:
                      [3, 4, 5].includes(_vm.jobDataInner.status) ||
                      !_vm.isJobCreatorOrCustomerEditView,
                    "label-position": "top",
                    "validate-on-rule-change": false,
                  },
                },
                [
                  _c(
                    "base-detail-card",
                    { attrs: { title: "Supply Schedule" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Assigned Barge Name",
                                    prop: "bargeShipId",
                                  },
                                },
                                [
                                  _c("barge-or-vessel-selector", {
                                    attrs: {
                                      disabled:
                                        _vm.jobDataInner.status !== 1 ||
                                        !_vm.isJobCreatorOrCustomerEditView,
                                      schedulFormData:
                                        _vm.deliveredScheduleFormData,
                                      availableBargesLoading: false,
                                      availableBargesOptions:
                                        _vm.availableBargesOptionsInner,
                                    },
                                    on: {
                                      handleChangeBarge: _vm.handleChangeBarge,
                                      visibleChange: _vm.visibleChange,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 24, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Vessel ETA" } },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      type: "datetime",
                                      format: "yyyy-MM-dd HH:mm",
                                      placeholder: _vm.$t("selectDate"),
                                    },
                                    on: {
                                      change: (e) =>
                                        (_vm.deliveredScheduleFormData.vesselEta =
                                          e ? e.toISOString() : null),
                                    },
                                    model: {
                                      value:
                                        _vm.deliveredScheduleFormData.vesselEta,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.deliveredScheduleFormData,
                                          "vesselEta",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "deliveredScheduleFormData.vesselEta",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 24, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Supply Location" } },
                                [
                                  _c("el-cascader", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      clearable: "",
                                      options: _vm.locationList,
                                      props: _vm.locationOptionProps,
                                      "show-all-levels": false,
                                    },
                                    on: { change: _vm.supplyLocationChange },
                                    model: {
                                      value: _vm.supplyLocation,
                                      callback: function ($$v) {
                                        _vm.supplyLocation = $$v
                                      },
                                      expression: "supplyLocation",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 24, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "supplyType",
                                    label: "Supply Type",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "Select",
                                        disabled: "",
                                      },
                                      model: {
                                        value:
                                          _vm.deliveredScheduleFormData
                                            .supplyType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.deliveredScheduleFormData,
                                            "supplyType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "deliveredScheduleFormData.supplyType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.SUPPLY_TYPE_OPTIONS,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 24, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.nominationReferenceLabel,
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value:
                                        _vm.deliveredScheduleFormData
                                          .nominationReference,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.deliveredScheduleFormData,
                                          "nominationReference",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "deliveredScheduleFormData.nominationReference",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 24, md: 8 } },
                            [
                              _vm.jobDataInner.status == 4 &&
                              _vm.isJobCreatorOrCustomerEditView
                                ? _c(
                                    "el-form",
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "Reference No." } },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value:
                                                _vm.deliveredScheduleFormData
                                                  .referenceNo,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.deliveredScheduleFormData,
                                                  "referenceNo",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "deliveredScheduleFormData.referenceNo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "el-form-item",
                                    { attrs: { label: "Reference No." } },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value:
                                            _vm.deliveredScheduleFormData
                                              .referenceNo,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.deliveredScheduleFormData,
                                              "referenceNo",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "deliveredScheduleFormData.referenceNo",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 24, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Final Quantity (MT)" } },
                                [
                                  _c("el-input", {
                                    staticClass: "input-with-select",
                                    attrs: { type: "number" },
                                    model: {
                                      value:
                                        _vm.deliveredScheduleFormData.finalQty,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.deliveredScheduleFormData,
                                          "finalQty",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "deliveredScheduleFormData.finalQty",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.deliveredScheduleFormData.finalQtyLiter != null
                            ? _c(
                                "el-col",
                                { attrs: { sm: 24, md: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "Final Quantity (Litres)",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticClass: "input-with-select",
                                        attrs: { type: "number", disabled: "" },
                                        model: {
                                          value:
                                            _vm.deliveredScheduleFormData
                                              .finalQtyLiter,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.deliveredScheduleFormData,
                                              "finalQtyLiter",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "deliveredScheduleFormData.finalQtyLiter",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-col",
                            { attrs: { sm: 24, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "fuelTypeCode",
                                    label: "Fuel Type (For MPA Reporting)",
                                    rules: [
                                      {
                                        required: true,
                                        message: "This field is required",
                                        trigger: ["blur", "change"],
                                      },
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        placeholder: "Select",
                                        loading:
                                          !_vm.mpaFuelTypeOptions.length > 0,
                                        disabled: _vm.disabledForVictory,
                                      },
                                      on: {
                                        "visible-change":
                                          _vm.getMpaFuelTypeListSortByRecent,
                                      },
                                      model: {
                                        value:
                                          _vm.deliveredScheduleFormData
                                            .fuelTypeCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.deliveredScheduleFormData,
                                            "fuelTypeCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "deliveredScheduleFormData.fuelTypeCode",
                                      },
                                    },
                                    _vm._l(
                                      _vm.mpaFuelTypeOptions,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            label: item.label,
                                            value: item.value,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("fuel-type-related-information", {
                        attrs: {
                          schedulFormData: _vm.deliveredScheduleFormData,
                        },
                        on: {
                          "update:schedulFormData": function ($event) {
                            _vm.deliveredScheduleFormData = $event
                          },
                          "update:schedul-form-data": function ($event) {
                            _vm.deliveredScheduleFormData = $event
                          },
                        },
                      }),
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "numberOfBdn",
                                    label: "No. Of BDN",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "100%" },
                                      on: {
                                        change: _vm.handleChangeNumberOfBdn,
                                      },
                                      model: {
                                        value:
                                          _vm.deliveredScheduleFormData
                                            .numberOfBdn,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.deliveredScheduleFormData,
                                            "numberOfBdn",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "deliveredScheduleFormData.numberOfBdn",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-option",
                                        { attrs: { value: "1" } },
                                        [_vm._v("1")]
                                      ),
                                      _c(
                                        "el-option",
                                        { attrs: { value: "2" } },
                                        [_vm._v("2")]
                                      ),
                                      _c(
                                        "el-option",
                                        { attrs: { value: "3" } },
                                        [_vm._v("3")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.isExxonMobil
                            ? _c(
                                "el-col",
                                { attrs: { sm: 12, md: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "customerName",
                                        label: "Customer Name",
                                        rules: _vm.rules.reqRule,
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value:
                                            _vm.deliveredScheduleFormData
                                              .customerName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.deliveredScheduleFormData,
                                              "customerName",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "deliveredScheduleFormData.customerName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.deliveredScheduleFormData.bdnQuantities?.length > 1
                        ? _c(
                            "el-row",
                            {
                              staticStyle: { "padding-top": "0" },
                              attrs: { gutter: 10 },
                            },
                            _vm._l(
                              _vm.deliveredScheduleFormData.bdnQuantities,
                              function (item, index) {
                                return _c(
                                  "el-col",
                                  { key: index, attrs: { sm: 24, md: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          prop: `bdnQuantities.${index}`,
                                          label: `Bdn Quantity ${index + 1}`,
                                          rules: _vm.rules.bdnQuantity,
                                        },
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: { type: "number", min: 0 },
                                          model: {
                                            value:
                                              _vm.deliveredScheduleFormData
                                                .bdnQuantities[index],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.deliveredScheduleFormData
                                                  .bdnQuantities,
                                                index,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "deliveredScheduleFormData.bdnQuantities[index]",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "base-detail-card",
                    { attrs: { title: "Vessel Information" } },
                    [
                      _c(
                        "el-row",
                        {
                          staticClass: "search-vessel-suffix-hidden",
                          attrs: { gutter: 10 },
                        },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "Search Vessel" } },
                                [
                                  _c("search-vessel-selector", {
                                    attrs: {
                                      searchedVesselList:
                                        _vm.searchedVesselList,
                                      cashType: null,
                                      deliveredScheduleFormData:
                                        _vm.deliveredScheduleFormData,
                                    },
                                    on: {
                                      "update:searchedVesselList": function (
                                        $event
                                      ) {
                                        _vm.searchedVesselList = $event
                                      },
                                      "update:searched-vessel-list": function (
                                        $event
                                      ) {
                                        _vm.searchedVesselList = $event
                                      },
                                      handleSelectVessel:
                                        _vm.handleSelectVessel,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { attrs: { gutter: 10 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "vesselName",
                                    label: "Vessel Name",
                                    rules: _vm.rules.reqRule,
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value:
                                        _vm.deliveredScheduleFormData
                                          .vesselName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.deliveredScheduleFormData,
                                          "vesselName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "deliveredScheduleFormData.vesselName",
                                    },
                                  }),
                                  _vm.receivingVesselMpaStatus &&
                                  _vm.deliveredScheduleFormData.vesselName !=
                                    null &&
                                  _vm.deliveredScheduleFormData.vesselName
                                    .length > 0
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "10px" },
                                        },
                                        [
                                          _vm._v(
                                            "(" +
                                              _vm._s(
                                                _vm.receivingVesselMpaStatus
                                              ) +
                                              ")"
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "IMO Number",
                                    prop: "vesselImo",
                                    rules: _vm.rules.vesselImoNo,
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value:
                                        _vm.deliveredScheduleFormData.vesselImo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.deliveredScheduleFormData,
                                          "vesselImo",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "deliveredScheduleFormData.vesselImo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { sm: 12, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "vesselLicenseNo",
                                    label: "License Number",
                                    rules: _vm.LICENSE_NUMBKER_RULE,
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value:
                                        _vm.deliveredScheduleFormData
                                          .vesselLicenseNo,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.deliveredScheduleFormData,
                                          "vesselLicenseNo",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "deliveredScheduleFormData.vesselLicenseNo",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { sm: 24, md: 8 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "vesselEmail",
                                    label: "Email",
                                    rules: _vm.GENERAL_EMAIL_RULE,
                                  },
                                },
                                [
                                  _c("el-input", {
                                    model: {
                                      value:
                                        _vm.deliveredScheduleFormData
                                          .vesselEmail,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.deliveredScheduleFormData,
                                          "vesselEmail",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "deliveredScheduleFormData.vesselEmail",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "base-detail-card",
                    { attrs: { title: "Remarks" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "remark" } },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", rows: 2 },
                                model: {
                                  value: _vm.deliveredScheduleFormData.remark,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.deliveredScheduleFormData,
                                      "remark",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "deliveredScheduleFormData.remark",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form",
            [
              _vm.jobDataInner.type === 1
                ? _c(
                    "base-detail-card",
                    { attrs: { title: "Audit Trails" } },
                    [
                      _c("audit-trails-information", {
                        attrs: { jobId: _vm.jobData.id },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.jobData.status === 4
                ? _c(
                    "base-detail-card",
                    {
                      attrs: {
                        title: "MPA Transaction Information",
                        action: _vm.mpaInfoActions,
                      },
                    },
                    [
                      _c("mpa-transaction-information", {
                        attrs: { mpaTransactionData: _vm.mpaTransactionData },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.sgTradexState &&
              _vm.jobData.status === 4 &&
              _vm.sgTradexState.isSgTradexInfoVisible &&
              _vm.jobDataInner.type === 1
                ? _c(
                    "base-detail-card",
                    {
                      attrs: {
                        title: "SgTradex Transaction Information",
                        action: _vm.sgTradexState.actions,
                      },
                    },
                    [
                      _c("sg-tradex-transaction-information", {
                        attrs: {
                          sgTradexTransactionData:
                            _vm.sgTradexState.sgTradexTransactionData,
                          loading: _vm.sgTradexState.sgTradexTransactionLoading,
                        },
                        on: {
                          "update:sgTradexTransactionData": function ($event) {
                            return _vm.$set(
                              _vm.sgTradexState,
                              "sgTradexTransactionData",
                              $event
                            )
                          },
                          "update:sg-tradex-transaction-data": function (
                            $event
                          ) {
                            return _vm.$set(
                              _vm.sgTradexState,
                              "sgTradexTransactionData",
                              $event
                            )
                          },
                          "update:loading": function ($event) {
                            return _vm.$set(
                              _vm.sgTradexState,
                              "sgTradexTransactionLoading",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("br"),
          _c("el-divider"),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "space-between",
                "padding-inline": "10px",
              },
            },
            [
              _c("h1", [_vm._v("Order Details")]),
              _c("el-button", {
                staticStyle: { height: "fit-content", "align-self": "center" },
                attrs: {
                  circle: "",
                  icon: _vm.orderVisible
                    ? "el-icon-arrow-down"
                    : "el-icon-arrow-right",
                },
                on: {
                  click: function ($event) {
                    _vm.orderVisible = !_vm.orderVisible
                  },
                },
              }),
            ],
            1
          ),
          _vm.orderVisible
            ? _c("order-form", {
                attrs: {
                  propsView: "VIEW_ORDER",
                  orderType: _vm.orderType,
                  isOrderTopBar: "",
                  isViewOnly: "",
                  isOps: "",
                  propsTradeType: _vm.tradeType,
                  orderId: _vm.jobDataInner.orderId,
                },
              })
            : _vm._e(),
          !_vm.orderVisible ? _c("el-divider") : _vm._e(),
        ],
        1
      ),
      _vm.submittedVisible
        ? _c("submitted", {
            attrs: { "response-data": _vm.responseData },
            on: {
              closed: function ($event) {
                _vm.submittedVisible = false
              },
            },
          })
        : _vm._e(),
      _c("link-order-dialog", {
        attrs: {
          visible: _vm.contractVisible,
          "initial-data": _vm.contractFormData.id ? _vm.contractFormData : null,
          orderMode: "spot",
        },
        on: {
          "update:visible": function ($event) {
            _vm.contractVisible = $event
          },
          getValue: _vm.getOrder,
        },
      }),
      _c("file-preview-list", {
        attrs: {
          visible: _vm.attachmentsVisible,
          order: _vm.contractFormData,
          files: _vm.attachments,
        },
        on: {
          "update:visible": function ($event) {
            _vm.attachmentsVisible = $event
          },
        },
      }),
      _vm.emailVisible
        ? _c("emails", {
            attrs: {
              nominations: _vm.nominationFormData,
              "order-id": _vm.contractFormData.id || undefined,
            },
            on: {
              "update:nominations": function ($event) {
                _vm.nominationFormData = $event
              },
              closed: function ($event) {
                _vm.emailVisible = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }