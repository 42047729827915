import { render, staticRenderFns } from "./SgTradexTransactionInformation.vue?vue&type=template&id=17543188&scoped=true&"
import script from "./SgTradexTransactionInformation.vue?vue&type=script&lang=js&"
export * from "./SgTradexTransactionInformation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17543188",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chris/IdeaProjects/portal/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('17543188')) {
      api.createRecord('17543188', component.options)
    } else {
      api.reload('17543188', component.options)
    }
    module.hot.accept("./SgTradexTransactionInformation.vue?vue&type=template&id=17543188&scoped=true&", function () {
      api.rerender('17543188', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/businessManager/operations/components/SgTradexTransactionInformation.vue"
export default component.exports